import { updateBreadcrumb } from "@/core/helpers/update-breadcrumbs"
import { useAuthStore } from "@/core/stores/auth-store"
import { useTitle } from "@vueuse/core"
import Vue from "vue"
import VueRouter, { Route, RouteConfig } from "vue-router"

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "LandingIndex",
    redirect: "/masuk",
    // component: () => import("@/modules/landing/pages/index.vue"),
    // meta: {
    //   title: "Aplikasi Absensi Honorer",
    // },
  },
  {
    path: "/masuk",
    name: "SignIn",
    component: () => import("@/modules/landing/pages/sign-in.vue"),
    meta: {
      title: "Masuk",
    },
  },
  // {
  //   path: "/daftar",
  //   name: "SignUp",
  //   component: () => import("@/modules/landing/pages/sign-up.vue"),
  //   meta: {
  //     title: "Daftar",
  //   },
  // },
  {
    path: "/app",
    name: "AppLayout",
    component: () => import("@/modules2/layouts/app-layout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/modules2/app/home/pages/index.vue"),
        meta: {
          title: "Utama",
        },
      },
      {
        path: "import",
        name: "Import",
        component: () => import("@/modules2/app/imports/pages/index.vue"),
        meta: {
          title: "Import",
        },
      },
      {
        path: "surat",
        name: "Mails",
        component: () => import("@/modules2/app/mail/pages/index.vue"),
        meta: {
          title: "Surat Masuk",
        },
      },
      {
        path: "surat/tambah",
        name: "MailCreate",
        component: () => import("@/modules2/app/mail/pages/form.vue"),
        meta: {
          title: "Tambah Surat Masuk",
        },
      },
      {
        path: "surat/:id/edit",
        name: "MailCreate",
        component: () => import("@/modules2/app/mail/pages/form.vue"),
        props: true,
        meta: {
          title: "Edit Surat Masuk",
        },
      },
      {
        path: "surat/:id",
        name: "MailDetail",
        component: () => import("@/modules2/app/mail/pages/detail.vue"),
        props: true,
        meta: {
          title: "Detail Surat Masuk",
        },
      },

      // Users
      {
        path: "pengguna",
        name: "Users",
        component: () => import("@/modules2/app/user/pages/index.vue"),
        meta: {
          title: "Pengguna",
        },
      },
      {
        path: "pengguna/tambah",
        name: "UserCreate",
        component: () => import("@/modules2/app/user/pages/form.vue"),
        meta: {
          title: "Tambah Pengguna",
        },
      },
      {
        path: "pengguna/:id/edit",
        name: "UserEdit",
        component: () => import("@/modules2/app/user/pages/form.vue"),
        props: true,
        meta: {
          title: "Edit Pengguna",
        },
      },

      // Me
      {
        path: "saya",
        name: "MeProfile",
        component: () => import("@/modules2/app/me/pages/profile.vue"),
        meta: {
          title: "Profil Saya",
        },
      },
      {
        path: "saya/ganti-password",
        name: "MeChangePassword",
        component: () => import("@/modules2/app/me/pages/change-password.vue"),
        meta: {
          title: "Ganti Password",
        },
      },

      // Jobs
      {
        path: "pekerjaan",
        name: "Jobs",
        component: () => import("@/modules2/app/jobs/pages/index.vue"),
        meta: {
          title: "Pekerjaan",
        },
      },
      {
        path: "pekerjaan/tambah",
        name: "JobCreate",
        component: () => import("@/modules2/app/jobs/pages/form.vue"),
        meta: {
          title: "Tambah Pekerjaan",
        },
      },
      {
        path: "pekerjaan/:id/edit",
        name: "JobEdit",
        component: () => import("@/modules2/app/jobs/pages/form.vue"),
        props: true,
        meta: {
          title: "Edit Pekerjaan",
        },
      },
      {
        path: "pekerjaan/:id/atur",
        name: "JobAssign",
        component: () => import("@/modules2/app/jobs/pages/assign.vue"),
        props: true,
        meta: {
          title: "Atur Pekerjaan",
        },
      },

      // Locations
      {
        path: "lokasi",
        name: "Locations",
        component: () => import("@/modules2/app/locations/pages/index.vue"),
        meta: {
          title: "Lokasi",
        },
      },
      {
        path: "lokasi/tambah",
        name: "LocationCreate",
        component: () => import("@/modules2/app/locations/pages/form.vue"),
        meta: {
          title: "Tambah Lokasi",
        },
      },
      {
        path: "lokasi/:id/edit",
        name: "LocationEdit",
        component: () => import("@/modules2/app/locations/pages/form.vue"),
        props: true,
        meta: {
          title: "Edit Lokasi",
        },
      },
      {
        path: "lokasi/:id/atur",
        name: "LocationAssign",
        component: () => import("@/modules2/app/locations/pages/assign.vue"),
        props: true,
        meta: {
          title: "Atur Lokasi",
        },
      },

      // Schedules
      {
        path: "jadwal",
        name: "Schedules",
        component: () => import("@/modules2/app/schedules/pages/index.vue"),
        meta: {
          title: "Jadwal",
        },
      },
      {
        path: "jadwal/tambah",
        name: "ScheduleCreate",
        component: () => import("@/modules2/app/schedules/pages/form.vue"),
        meta: {
          title: "Tambah Jadwal",
        },
      },
      {
        path: "jadwal/:id/edit",
        name: "ScheduleEdit",
        component: () => import("@/modules2/app/schedules/pages/form.vue"),
        props: true,
        meta: {
          title: "Edit Jadwal",
        },
      },
      {
        path: "jadwal/:id/atur",
        name: "ScheduleAssign",
        component: () => import("@/modules2/app/schedules/pages/assign.vue"),
        props: true,
        meta: {
          title: "Atur Jadwal",
        },
      },

      // Holidays
      {
        path: "hari-libur",
        name: "Holidays",
        component: () => import("@/modules2/app/holidays/pages/index.vue"),
        meta: {
          title: "Hari Libur",
        },
      },
      {
        path: "hari-libur/tambah",
        name: "HolidayCreate",
        component: () => import("@/modules2/app/holidays/pages/form.vue"),
        meta: {
          title: "Tambah Hari Libur",
        },
      },
      {
        path: "hari-libur/:id/edit",
        name: "HolidayEdit",
        component: () => import("@/modules2/app/holidays/pages/form.vue"),
        props: true,
        meta: {
          title: "Edit Hari Libur",
        },
      },

      // Attendances
      {
        path: "presensi",
        name: "Attendances",
        component: () => import("@/modules2/app/attendance/pages/index.vue"),
        meta: {
          title: "Presensi",
        },
      },

      // Vacations
      {
        path: "cuti",
        name: "Vacations",
        component: () => import("@/modules2/app/vacations/pages/index.vue"),
        meta: {
          title: "Cuti",
        },
      },
      {
        path: "cuti/tambah",
        name: "VacationCreate",
        component: () => import("@/modules2/app/vacations/pages/form.vue"),
        meta: {
          title: "Tambah Cuti",
        },
      },
      {
        path: "cuti/:id/edit",
        name: "VacationEdit",
        component: () => import("@/modules2/app/vacations/pages/form.vue"),
        props: true,
        meta: {
          title: "Edit Cuti",
        },
      },

      // Official Trips
      {
        path: "tugas-luar",
        name: "OfficialTrips",
        component: () =>
          import("@/modules2/app/official-trips/pages/index.vue"),
        meta: {
          title: "Tugas Luar",
        },
      },
      {
        path: "tugas-luar/tambah",
        name: "OfficialTripCreate",
        component: () => import("@/modules2/app/official-trips/pages/form.vue"),
        meta: {
          title: "Tambah Tugas Luar",
        },
      },
      {
        path: "tugas-luar/:id/edit",
        name: "OfficialTripEdit",
        component: () => import("@/modules2/app/official-trips/pages/form.vue"),
        props: true,
        meta: {
          title: "Edit Tugas Luar",
        },
      },
    ],
  },
]

const router = new VueRouter({
  routes,
  mode: "history",
})

const updateTitle = (to: Route) => {
  const defaultTitle = "Aplikasi Absensi Honorer"
  const titleMeta = to?.meta?.title

  useTitle(titleMeta ?? defaultTitle, {
    titleTemplate: `%s | ${process.env.VUE_APP_NAME}`,
  })
}

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0)
  updateTitle(to)

  const isRequiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (!isRequiresAuth) return next()

  const { me, getMe } = useAuthStore()

  let error: any = null
  if (!me) {
    const response = await getMe()
    if (response?.kind !== "ok") error = response
  }

  const isUnauthorized =
    error?.message === "Unauthorized" ?? error?.statusText === "Unauthorized"
  const onAuthPage = to.name === "SignIn" || to.name === "SignUp"
  const onAppPage = to.fullPath.includes("/app")
  const redirectToLoginPage = isUnauthorized && isRequiresAuth && onAppPage
  const redirectToAppPage = onAuthPage && me.value && !isUnauthorized

  if (redirectToLoginPage)
    return next({
      path: "/masuk",
      query: { redirect: to.fullPath },
    })

  if (redirectToAppPage) return next({ path: "/app" })

  updateBreadcrumb(to)

  next()
})

export default router
