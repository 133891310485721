import { computed, ref } from "@vue/composition-api"
import { defineStore } from "pinia"
import { Breadcrumb, Navigation } from "../types/Ui"
import { useAuthStore } from "./auth-store"

const allNavigations = {
  main: {
    text: "Utama",
    icon: "mdi-home-outline",
    to: "/app",
    exact: true,
  },
  import: {
    text: "Import",
    icon: "mdi-database-import-outline",
    to: "/app/import",
  },
  presence: {
    text: "Presensi",
    icon: "mdi-calendar-account-outline",
    to: "/app/presensi",
  },
  job: { text: "Pekerjaan", icon: "mdi-city", to: "/app/pekerjaan" },
  location: {
    text: "Lokasi",
    icon: "mdi-map-marker-outline",
    to: "/app/lokasi",
  },
  schedule: {
    text: "Jadwal",
    icon: "mdi-calendar-outline",
    to: "/app/jadwal",
  },
  vacation: { text: "Cuti", icon: "mdi-calendar-outline", to: "/app/cuti" },
  "official-trip": {
    text: "Tugas Luar",
    icon: "mdi-calendar-star-outline",
    to: "/app/tugas-luar",
  },
  holiday: {
    text: "Hari Libur",
    icon: "mdi-calendar-remove-outline",
    to: "/app/hari-libur",
  },
  user: {
    text: "Pengguna",
    icon: "mdi-account-multiple-outline",
    to: "/app/pengguna",
  },
  me: {
    text: "Profil Saya",
    icon: "mdi-account-outline",
    to: "/app/saya",
  },
}

export const useUiStore = defineStore("ui", () => {
  const auth = useAuthStore()
  const navigations = computed<Navigation[]>(() => {
    const n = allNavigations
    if (auth.isAdmin || auth.isSuperAdmin)
      return [
        n.main,
        n.presence,
        n.job,
        n.location,
        n.schedule,
        n.vacation,
        n["official-trip"],
        n.holiday,
        n.import,
        n.user,
        n.me,
      ]
    if (auth.isUser)
      return [n.main, n.presence, n.vacation, n["official-trip"], n.me]

    return []
  })
  const activeNavigation = ref<boolean | null>(null)
  const showBottomNavigation = ref<boolean | null>(true)
  const setShowBottomNavigation = (isShow: boolean) =>
    (showBottomNavigation.value = isShow)

  const appBarTitle = ref("")
  const setAppBarTitle = (title: string) => (appBarTitle.value = title)

  const breadcrumbs = ref<Breadcrumb[]>([
    {
      text: "Dashboard",
      disabled: false,
      to: "breadcrumbs_dashboard",
    },
    {
      text: "Link 1",
      disabled: false,
      to: "breadcrumbs_link_1",
    },
    {
      text: "Link 2",
      disabled: true,
      to: "breadcrumbs_link_2",
      color: "black",
    },
  ])
  const setBreadcrumbs = (_breadcrumbs: Breadcrumb[]) =>
    (breadcrumbs.value = _breadcrumbs)

  return {
    navigations,
    activeNavigation,
    appBarTitle,
    setAppBarTitle,
    breadcrumbs,
    setBreadcrumbs,
    showBottomNavigation,
    setShowBottomNavigation,
  }
})
