import Vue from "vue"
import "@/core/services/composition-api"
import { usePinia as pinia } from "@/core/services/pinia"
import App from "./App.vue"
import "./registerServiceWorker"
import "@/core/styles/index.scss"
import router from "./router"
import vuetify from "./plugins/vuetify"

Vue.config.productionTip = false

new Vue({
  pinia,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
